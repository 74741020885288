import 'vanilla-cookieconsent/dist/cookieconsent.css';
import { run as CookieConsentRun } from 'vanilla-cookieconsent';
import config from './config';

export default {
  install: (app: any) => {
    CookieConsentRun(config).then((instance) => {
      app.config.globalProperties.$CC = instance;
    }).catch((error: any) => {
      console.error('Erreur lors de l\'initialisation de Cookie Consent:', error);
    });
  },
};