import "./styles/main.css";
import "@lib/themes/mobiscroll.scss";

import { createApp } from "vue";
import App from "@src/App.vue";
import { router } from "./router";

import { i18n } from "./i18n";
import tippyPlugin from "@lib/plugins/TippyPlugin";

import formkitConfig from "@src/formkit";

import Bugsnag from "@bugsnag/js";
import BugsnagPluginVue from "@bugsnag/plugin-vue";

import { toastPlugin } from "@lib/plugins/ToastPlugin";
import { modalAlertPlugin } from "@lib/plugins/ModalAlertPlugin";

import { HTTPError } from "ky";
import { pwaPlugin } from "./plugins/pwa";
import { User } from "./types/services/auth.service";
import truncatPlugin from "@lib/plugins/TruncatPlugin";

import { analytics } from './plugins/analytics';
import cookieConsentPlugin from './plugins/cookie-consent/main';

export const app = createApp(App);
const pinia = createPinia();

const reloadRegex = [
  /Failed to fetch dynamically imported module/i,
  /^.*'text\/html' is not a valid JavaScript MIME type.*/i,
  /^.*Importing a module script failed.*/i,
  /^.*Unable to preload CSS.*/i,
];

analytics.init(app, router);

if (!import.meta.env.DEV && import.meta.env.VITE_BUGSNAG_API_KEY) {
  Bugsnag.start({
    apiKey: import.meta.env.VITE_BUGSNAG_API_KEY,
    plugins: [new BugsnagPluginVue()],
    appVersion: import.meta.env.VITE_LAST_GIT_HASH,
    onError: async (e) => {
      if (
        e.originalError instanceof Error &&
        reloadRegex.find((regex) => regex.test(e.originalError.message))
      ) {
        window.location.reload();
        return false;
      }

      if (e.originalError instanceof HTTPError) {
        if (e.originalError.response.status === 401) return false;

        if (
          e.originalError.request.headers.get("content-type") ===
          "application/json"
        ) {
          e.addMetadata("request", {
            body: await e.originalError.request.json(),
          });
        }
        if (
          e.originalError.request.headers
            .get("content-type")
            ?.startsWith("multipart/form-data")
        ) {
          e.addMetadata("request", {
            formData: await e.originalError.request.formData(),
          });
        }
        if (
          e.originalError.response.headers.get("content-type") ===
          "application/json"
        ) {
          e.addMetadata("response", {
            body: await e.originalError.response.json(),
          });
        }

        if (pinia.state.value.auth?.user) {
          e.addMetadata("user", {
            uuid: (pinia.state.value.auth.user as User).uuid,
          });
        }
      }
    },
  });

  const bugsnagVue = Bugsnag.getPlugin("vue");
  if (bugsnagVue) app.use(bugsnagVue);
}

app.config.globalProperties.$dayjs = dayjs;

app.use(i18n);
app.use(router);
app.use(pwaPlugin);
app.use(pinia);
app.use(toastPlugin);
app.use(modalAlertPlugin);
app.use(tippyPlugin);
app.use(truncatPlugin);
app.use(cookieConsentPlugin);
app.use(plugin, formkitConfig);
app.mount("#app");