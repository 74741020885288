import { CookieConsentConfig } from 'vanilla-cookieconsent';
import { analytics } from "@src/plugins/analytics";
import translations from './translations';

const config: CookieConsentConfig = {
  guiOptions: {
    consentModal: {
      layout: "bar inline",
      position: "bottom right",
      equalWeightButtons: true,
      flipButtons: false,
    },
    preferencesModal: {
      layout: "box",
      position: "right",
      equalWeightButtons: true,
      flipButtons: false,
    },
  },
  categories: {
    necessary: {
      readOnly: true,
      enabled: true,
    },
    analytics: {
      autoClear: {
        cookies: [
          {
            name: /^(_ga|_gid)/,
          },
        ],
      },
    },
    ads: {},
  },

  onFirstConsent: ({ cookie }: any) => {
    if (cookie?.categories?.includes('analytics')) {
      analytics.methods.enable();
    }
  },

  onChange: ({ cookie }: any) => {
    if (cookie?.categories?.includes('analytics')) {
      analytics.methods.enable();
    } else {
      analytics.methods.disable();
    }
  },

  language: {
    default: "fr",
    autoDetect: "browser",
    translations,
  },
};

export default config;